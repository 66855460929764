import React from "react";
import Main from "../layouts/Main";
import MainBoxAsite from "../GeneralComponents/MainBoxAsite";
import BackHome from "../BuildDoor/BackHome";
import MainBoxOtherPanel from "../layouts/MainBoxOtherPanel";
import BuildDoorBoxItemSecond from "../ProjectDashboard/BuildDoorBoxItemSecond";
import ScrollTopBottom from "../layouts/scrollTopBottom";

class MyVirtualShowroom extends React.Component {
  render() {
    return (
      <Main header={true}>
        <ScrollTopBottom />
        <MainBoxAsite>
          <BackHome />
        </MainBoxAsite>
        <MainBoxOtherPanel title={"My Virtual Showroom"}>
          <div className="build-door__box showroom ">
            <BuildDoorBoxItemSecond
            // key={`brands_${index}`}
            // brand={brand}
            />
          </div>
        </MainBoxOtherPanel>
      </Main>
    );
  }
}

export default MyVirtualShowroom;
