import { Component } from 'react'
import TitleConstructor from '../../ElementsConstructor/TitleConstructor'
import MobileTitleConstructorSecond from '../../ElementsConstructor/MobileTitleConstructorSecond'
import BuildItemsInnerBoxConstructorMain from '../../ElementsConstructor/BuildItemsInnerBoxConstructorMain'
import PreviewFurnitureGroup from '../FurnitureGroup/PreviewFurnitureGroup'
import FormFooterConstructorFurnitureTypeLetterplate from '../FurnitureTypeLetterplate/FormFooterConstructorFurnitureTypeLetterplate'
import BuildItemsConstructorWreath from '../../ElementsConstructor/BuildItemsConstructorWreath'
import { connect } from 'react-redux'
import { allSettings } from '../../../redux/actions/settings'

class FurnitureWreath extends Component {
  state = {
    settings: {},
  }
  componentDidMount() {
    this.props.loadWreaths()
    this.props.allSettings().then((response) => {
      this.setState({
        settings: { ...response },
      })
    })
  }

  render() {
    return (
      <>
        <TitleConstructor title="Magic Wreath Holder" classAdd="white" />
        <MobileTitleConstructorSecond
          title={
            <span>
              Apeer doors are available with internal magnets and a Magic Wreath
              Holder kit to attach your wreath to the door.{' '}
              <a
                style={{ color: 'rgb(199, 178, 153)' }}
                target="_blank"
                href={this.state.settings['site.file_of_banner']}
                rel="noreferrer"
              >
                Click here for more info
              </a>
            </span>
          }
        />
        <BuildItemsConstructorWreath>
          <BuildItemsInnerBoxConstructorMain
            classAdd="duplicate "
            classAddBox="mobile col2"
          >
            {this.props.wreaths.length === 0 ? <div>{'No wreaths'}</div> : null}
            {this.props.wreaths.map((wreath, index) => {
              return (
                <div
                  className={
                    'build__item build__marker-1 ' +
                    (this.props.checkLayerInModel(wreath).length > 0
                      ? 'active'
                      : '')
                  }
                  key={`wreath_index_${index}`}
                  onClick={() => {
                    this.props.chooseComponent(wreath)
                  }}
                >
                  <div className="build__item-img">
                    <img src={wreath.preview} alt="" />
                  </div>
                  <div className="build__item-name">{wreath.code}</div>
                </div>
              )
            })}
          </BuildItemsInnerBoxConstructorMain>
          <div
            style={{
              padding: 28,
              position: 'absolute',
              bottom: 0,
              background: '#fff',
            }}
          >
            **WREATHS ARE NOT INCLUDED – WREATH MAGNET ATTACHMENT KIT SUPPLIED
            WHEN ORDERED WITH DOOR**
            <br />
            <br />
            Images shown are for illustration purposes only and knocker images
            may not be to exact scale. Your final wreath position may vary
            slightly from images shown depending on door style and final
            dimensions.
          </div>
        </BuildItemsConstructorWreath>
        <FormFooterConstructorFurnitureTypeLetterplate
          link="/frame-style"
          greenButtonHandler={this.props.greenButtonHandler}
          backButtonHandler={this.props.backButtonHandler}
          setSeeMyDoor={this.props.setSeeMyDoor}
          setShowImageMobile={this.props.setShowImageMobile}
        />
        <PreviewFurnitureGroup
          image={this.props.image}
          showImageMobile={this.props.showImageMobile}
          setShowImageMobile={this.props.setShowImageMobile}
          setOrder={this.props.setOrder}
          order={this.props.order}
          scale={this.props.scale}
          setScale={this.props.setScale}
          backButtonHandler={this.props.backButtonHandler}
        />
      </>
    )
  }
}

function mapStateToProps(state) {
  return {}
}
function mapDispatchToProps(dispatch) {
  return {
    allSettings: () => {
      return dispatch(allSettings())
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FurnitureWreath)
