import React from 'react'
import TitleConstructor from '../../ElementsConstructor/TitleConstructor'
import MobileTitleConstructorSecond from '../../ElementsConstructor/MobileTitleConstructorSecond'
import BuildItemsConstructor from '../../ElementsConstructor/BuildItemsConstructor'
import BuildItemsInnerBoxConstructor from '../../ElementsConstructor/BuildItemsInnerBoxConstructor'
import BuildItemBuildMarker1 from '../../ElementsConstructor/BuildItemBuildMarker-1'
import FormFooterConstructorDoorStyle from './FormFooterConstructorDoorStyle'
import BuildItemsConstructorWreath from '../../ElementsConstructor/BuildItemsConstructorWreath'

class DoorStyleSecond extends React.Component {
  titleCase = (string) => {
    return !!string
      ? string[0].toUpperCase() + string.slice(1).toLowerCase()
      : ''
  }
  render() {
    let parts = this.props.parts.sort((a, b) => {
      if (a.order < b.order) {
        return -1
      }
      if (a.order > b.order) {
        return 1
      }
      return 0
    })
    return (
      <>
        <TitleConstructor
          title={`${this.props.brandTitle} Doors`}
          classAdd="white"
        />
        <MobileTitleConstructorSecond
          title={`Scroll to see our range of ${this.props.brandTitle} door styles`}
        />

        <BuildItemsConstructor>
          <BuildItemsInnerBoxConstructor>
            {parts.map((part, index) => {
              return (
                <BuildItemBuildMarker1
                  model={this.props.model}
                  checkLayerInModel={this.props.checkLayerInModel}
                  key={`part_${index}`}
                  part={part}
                  chooseComponent={this.props.chooseComponent}
                />
              )
            })}
          </BuildItemsInnerBoxConstructor>
        </BuildItemsConstructor>
        <FormFooterConstructorDoorStyle
          link="/frame-style-second"
          greenButtonHandler={this.props.greenButtonHandler}
          backButtonHandler={this.props.backButtonHandler}
          setSeeMyDoor={this.props.setSeeMyDoor}
          setShowImageMobile={this.props.setShowImageMobile}
          showImageMobile={this.props.showImageMobile}
        />
      </>
    )
  }
}

export default DoorStyleSecond
