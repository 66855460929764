import React from 'react'
import { connect, useSelector } from 'react-redux'

function ModalWarning(props) {
  let affiliate = useSelector((state) => state.affiliate.data)

  return (
    <div
      className="modal-warning"
      style={{
        backgroundColor: affiliate?.frontend_color ?? '#c7b299',
      }}
    >
      <div className="content">{props.message}</div>
      <div className="button">
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault()
            props.setStatus(false)
          }}
        >
          OK
        </a>
      </div>
    </div>
  )
}
function mapStateToProps(state) {
  return {
    affiliate: state.affiliate.data,
  }
}

export default connect(mapStateToProps)(ModalWarning)
