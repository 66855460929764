const initializeState = {
  mainUrl: 'https://api.apeer.co.uk',
  // mainUrl: 'http://apeer-backend',

  // mainUrl: 'https://apeer3.skander.pro',
}

export default function settingsReducer(state = initializeState, action) {
  switch (action.type) {
    default:
      return { ...state }
  }
}
