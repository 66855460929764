import React, { useEffect, useState } from 'react'
import Header from '../GeneralComponents/Header/Header'
import FooterPanelSecond from '../ElementsConstructor/FooterPanelSecond'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { showAffiliate } from './../../redux/actions/affiliate'

function Main(props) {
  let [affiliate, setAffiliate] = useState({})
  useEffect(() => {
    if (!!props.match.params.iframePartnerPath) {
      loadAffiliate(props.match.params.iframePartnerPath)
    }
  }, [])

  let loadAffiliate = (name) => {
    props.showAffiliate(name).then((response) => {
      setAffiliate({ ...response.data })
    })
  }
  return (
    <div className="App">
      <div className="main">
        {Object.keys(affiliate).length > 0 && affiliate.is_header ? (
          <Header affiliate={affiliate} />
        ) : null}

        <div
          className={
            'main-box' +
            ' ' +
            (props.classAdd !== undefined ? props.classAdd : '')
          }
        >
          {props.children}
        </div>
        {Object.keys(props.match).length > 0 &&
        props.match.url === '/summary' ? (
          <FooterPanelSecond />
        ) : null}
      </div>
    </div>
  )
}

function mapStateToProps(state) {
  return {}
}
function mapDispatchToProps(dispatch) {
  return {
    showAffiliate: (name) => {
      return dispatch(showAffiliate(name))
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Main))
