import React from "react";
import SimpleBar from "simplebar-react";
class EnergyEfficiencyListPanel extends React.Component {
  render() {
    let hash = window.location.hash;

    return (
      <div className="main-box-content__inner-list topPanel">
        <SimpleBar className="scrollable-area" forceVisible="y">
          <div className="main-box-content__inner__warpper">
            <div className="energy-efficiency-panel">
              <ul>
                <li>
                  <a href="/energy-efficiency">Energy Efficiency</a>
                </li>
                <li>
                  <a href="/security-features">Security Features</a>
                </li>
                <li>
                  <a href="/colour-choices">Colour Choices</a>
                </li>
                <li>
                  <a href="/glass-designs">Glass Designs</a>
                </li>
              </ul>
            </div>
          </div>
        </SimpleBar>
      </div>
    );
  }
}

export default EnergyEfficiencyListPanel;
