import React from "react";

class RightPartConstructor extends React.Component {
  render() {
    return (
      <div className="summary__img summary-preview">{this.props.children}</div>
    );
  }
}

export default RightPartConstructor;
