import axios from "axios";
import { SET_USER_TOKEN, LOGIN_SUCCESS } from "./actionTypes";

export function login(formData) {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;
    let response = await axios.post(`${mainUrl}/api/login`, {
      ...formData,
    });

    return response;
  };
}
export function checkProfile() {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;
    // let token = cookies.get("token");
    let response = await axios.get(`${mainUrl}/api/profile`, {
      headers: {
        // Authorization: `Bearer ${token}`,
      },
    });
    // dispatch(setUserProfile(response.data));
    return response;
  };
}

export function logout() {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;
    //let token = cookies.get("token");
    let response = await axios.post(
      `${mainUrl}/api/logout`,
      {},
      {
        headers: {
          //  Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  };
}

export function setUserToken(token) {
  return {
    type: LOGIN_SUCCESS,
    data: { token },
  };
}
