import React from "react";

class SummaryPanelMobile extends React.Component {
  render() {
    return (
      <div className="summary__panel-mobile">
        <div
          className={
            "summary__panel-mobile__item " +
            (this.props.page === "door_style" ? "active" : "")
          }
        >
          <a
            onClick={(e) => {
              e.preventDefault();

              this.props.setPage("door_style");
            }}
          >
            Door
          </a>
        </div>
        <div
          className={
            "summary__panel-mobile__item " +
            (this.props.page === "frame_style" ? "active" : "")
          }
        >
          <a
            onClick={(e) => {
              e.preventDefault();
              this.props.setPage("frame_style");
            }}
          >
            Frame
          </a>
        </div>
        <div
          className={
            "summary__panel-mobile__item " +
            (this.props.page === "outside-door-color" ||
            this.props.page === "outside-frame-color" ||
            this.props.page === "inside-door-color" ||
            this.props.page === "inside-frame-color"
              ? "active"
              : "")
          }
        >
          <a
            onClick={(e) => {
              e.preventDefault();
              this.props.setPage("outside-door-color");
            }}
          >
            Colour
          </a>
        </div>
        <div
          className={
            "summary__panel-mobile__item " +
            (this.props.page === "glass-design" ||
            this.props.page === "glass-frame"
              ? "active"
              : "")
          }
        >
          <a
            onClick={(e) => {
              e.preventDefault();
              this.props.setPage("glass-design");
            }}
          >
            Glass
          </a>
        </div>
        <div
          className={
            "summary__panel-mobile__item " +
            (this.props.page === "furniture-type-handle" ||
            this.props.page === "furniture-color-handle" ||
            this.props.page === "furniture-type-knocker" ||
            this.props.page === "furniture-type-letterplate" ||
            this.props.page === "furniture-side-hinges" ||
            this.props.page === "furniture-group"
              ? "active"
              : "")
          }
        >
          <a
            onClick={(e) => {
              e.preventDefault();
              this.props.setPage("furniture-type-handle");
            }}
          >
            Furniture
          </a>
        </div>
      </div>
    );
  }
}

export default SummaryPanelMobile;
