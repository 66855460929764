import React from "react";
import SimpleBar from "simplebar-react";

class MainBoxContentInnerList extends React.Component {
  render() {
    return (
      <div className="main-box-content__inner-list">
        <SimpleBar className="scrollable-area" forceVisible="y">
          <div className="main-box-content__inner__warpper">
            <div className="main-box-content__inner-list-title">
              FILTER BY RANGE
            </div>
            <div className="main-box-content__inner-list-listbox">
              <ul>
                <li>
                  <a href="#">APEER TRADITIONAL</a>
                </li>
                <li>
                  <a href="#">APEER CONTEMPORARY</a>
                </li>
                <li>
                  <a href="#">APEER SILKA</a>
                </li>
                <li>
                  <a href="#">APEER MODO</a>
                </li>
                <li>
                  <a href="#">APEER STABLE DOORS</a>
                </li>
                <li>
                  <a href="#">APEER FRENCH DOORS</a>
                </li>
                <li>
                  <a href="#">APEER 44 TRADITIONAL</a>
                </li>
                <li>
                  <a href="#">APEER ACCESSIBILITY DOORS</a>
                </li>
                <li>
                  <a href="#">DOUBLE DIAMOND DOORS</a>
                </li>
                <li>
                  <a href="#">DIAMOND DOORS</a>
                </li>
                <li>
                  <a href="#">OPEN OUT RANGE</a>
                </li>
              </ul>
              <div className="view-all">
                <a href="#">VIEW ALL</a>
              </div>
            </div>
            <div className="main-box-content__inner-list-budget">
              <span>{"BUDGET > £2000"}</span>
              <span>{"BUDGET < £2000"}</span>
            </div>
          </div>
        </SimpleBar>
      </div>
    );
  }
}

export default MainBoxContentInnerList;
