import React from "react";
import SimpleBar from "simplebar-react";

class MainBoxContentInnerContainer extends React.Component {
  render() {
    return (
      <div
        className={
          "main-box-content__inner-container" +
          " " +
          (this.props.classAdd !== undefined ? this.props.classAdd : "")
        }
      >
        <SimpleBar className="scrollable-area" forceVisible="y">
          <div className="container">
            <div className="build-door__box most-popular">
              {this.props.children}
            </div>
          </div>
        </SimpleBar>
      </div>
    );
  }
}

export default MainBoxContentInnerContainer;
