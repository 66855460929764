import React from "react";

class ScrollTopBottom extends React.Component {
  render() {
    return (
      <button className="scroll-top" data-component="ScrollTop">
        scroll
        <picture>
          <svg
            width="13"
            height="18"
            viewBox="0 0 13 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.00977 17.2V0.41C6.00977 0.18 6.18977 0 6.40977 0C6.63977 0 6.81977 0.18 6.81977 0.41V17.2C6.81977 17.43 6.63977 17.61 6.40977 17.61C6.17977 17.61 6.00977 17.42 6.00977 17.2Z"
              fill="#333333"
            />
            <path
              d="M6.00977 17.2C6.00977 17.1 6.04977 16.99 6.12977 16.91L12.1298 10.91C12.2898 10.75 12.5498 10.75 12.7098 10.91C12.8698 11.07 12.8698 11.33 12.7098 11.49L6.70977 17.49C6.54977 17.65 6.28977 17.65 6.12977 17.49C6.04977 17.41 6.00977 17.3 6.00977 17.2Z"
              fill="#333333"
            />
            <path
              d="M0 11.1898C0 11.0898 0.04 10.9798 0.12 10.8998C0.28 10.7398 0.54 10.7398 0.7 10.8998L6.71 16.8998C6.87 17.0598 6.87 17.3198 6.71 17.4798C6.55 17.6398 6.29 17.6398 6.13 17.4798L0.12 11.4798C0.04 11.3998 0 11.2998 0 11.1898Z"
              fill="#333333"
            />
          </svg>
        </picture>
      </button>
    );
  }
}

export default ScrollTopBottom;
