import axios from 'axios'

export function getBrands(affiliate = null) {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl
    let response = await axios.get(
      mainUrl + '/api/brands' + (!!affiliate ? `/${affiliate}` : '')
    )
    return response
  }
}

export function getBrand(slug, affiliate = null) {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl
    let response = await axios.get(
      mainUrl + `/api/brand/${slug}` + (!!affiliate ? `/${affiliate}` : '')
    )
    return response
  }
}
