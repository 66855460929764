import React from "react";

class MainTitleBlackTextSearch extends React.Component {
  render() {
    return (
      <div className="main-title">
        <div className="container">
          <div class="main-title__inner">
            <h1 className="black-text">{this.props.children}</h1>
            <div class="search" data-component="Search">
              <input type="text" placeholder="ENTER DOOR CODE" />
              <button type="button">SEARCH</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MainTitleBlackTextSearch;
