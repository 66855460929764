import React, { Component } from "react";

class PreviewGlassDesign extends Component {
  render() {
    return (
      <div
        className={
          "preview-door " + (this.props.showImageMobile ? "active" : "")
        }
      >
        <div className="preview-door__inner">
          <div className="preview-door__title ">Glass design</div>
          <div className="preview-door__img ">
            <div className="preview-door__img-box">
              <img
                src={this.props.image}
                alt=""
                style={{
                  transform: `scale(${this.props.scale})`,
                }}
              />
            </div>
          </div>
          <div
            className="preview-door__label "
            onClick={() => {
              this.props.setShowImageMobile(false);
            }}
          >
            <div className="preview-door__label-text green">CHOOSE</div>
            <div className="preview-door__label-text">GLASS DESIGN</div>
          </div>
        </div>
      </div>
    );
  }
}
export default PreviewGlassDesign;
