import axios from "axios";

export function loadProducts(category) {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;
    let response = await axios.get(
      mainUrl + `/api/products/search/${category}`
    );
    return response;
  };
}

export function loadProductCategories() {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;
    let response = await axios.get(mainUrl + `/api/product-categories`);
    return response;
  };
}

export function loadProduct(id) {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;
    let response = await axios.get(mainUrl + `/api/products/${id}`);
    return response;
  };
}
