import React, { Component } from "react";

class FrameStyleItem extends Component {
  render() {
    let check = this.props.checkLayerInModel(this.props.part);

    return (
      <div
        className={
          "build__item build__marker-1 item " +
          (check.length > 0 ? "active" : "")
        }
        onClick={() => {
          this.props.chooseComponent(this.props.part);
        }}
      >
        <div className="build__item-img">
          <img src={this.props.part.preview} alt={""} />
        </div>
        <div className="build__item-name">{this.props.part.name}</div>
      </div>
    );
  }
}

export default FrameStyleItem;
