import React from 'react'
import { connect } from 'react-redux'
import SimpleBar from 'simplebar-react'
import SeeOnMyHouseBottpanel from './SeeOnMyHouseBottpanel'
import SeeOnMyHousePreviewMobile from './SeeOnMyHousePreviewMobile'
class SeeOnMyHousePanelGallery extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <div className="summary__con-inner gallery">
        <div className="summary__con ">
          <div
            className="summary__title white "
            style={{
              backgroundColor:
                this.props.affiliate?.frontend_color ?? '#c7b299',
            }}
          >
            My Dream Door
          </div>
          <div className="simple-bar__wrapper">
            <SimpleBar className="scrollable-area" forceVisible="y">
              <div className="simple-bar__inner">
                <SeeOnMyHousePreviewMobile
                  activeHouse={this.props.activeHouse}
                  setMyDoorEditor={this.props.setMyDoorEditor}
                  myDoorEditor={this.props.myDoorEditor}
                  image={this.props.image}
                />

                <div className="build__title-mobile build__title-mobile--active">
                  If you do not have a suitable photograph of your own house,
                  why not try a similar style of building from our images below.
                </div>

                <div className="build__items height-auto">
                  <div className="build__items-inner-box ">
                    <div className="owl-theme build__items-inner col-3 gallery">
                      {this.props.houses.map((house, index) => {
                        return (
                          <div
                            key={`house_${index}`}
                            className={
                              'build__item build__marker-1 white  ' +
                              (this.props.activeHouse.id === house.id
                                ? 'active'
                                : '')
                            }
                            onClick={() => {
                              this.props.setActiveHouse(house)
                            }}
                          >
                            <div className="build__item-img">
                              <img src={house.thumb} alt="" />
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </SimpleBar>
          </div>
          <div className="summary-btn-box">
            <a
              className="global-btn global-btn-arrow"
              onClick={(e) => {
                e.preventDefault()
                this.props.hideSeeMyDoor()
              }}
            >
              REQUEST A PRICE
            </a>
          </div>
          <SeeOnMyHouseBottpanel
            myDoorEditor={this.props.myDoorEditor}
            setSeeMyDoor={this.props.setSeeMyDoor}
            setMyDoorEditor={this.props.setMyDoorEditor}
            hideSeeMyDoor={this.props.hideSeeMyDoor}
          />
        </div>
      </div>
    )
  }
}
function mapStateToProps(state) {
  return {
    affiliate: state.affiliate.data,
  }
}

export default connect(mapStateToProps)(SeeOnMyHousePanelGallery)
