import React from 'react'
import { Link } from 'react-router-dom'

class MostPopularDoorsItemList extends React.Component {
  render() {
    return (
      <div className="build-door__box-item">
        <a href="#">
          <div className="build-door__box-item-img">
            <img src={this.props.product.preview} alt="" />
          </div>
        </a>
        <div className="build-door__box-item-name">Doorname</div>
        <div className="build-door__box-item-footer-link">
          <a
            className="build-door__link-house"
            href="#"
            onClick={(event) => {
              event.preventDefault()
              this.props.showSeeMyDoor(this.props.product)
            }}
          >
            REQUEST A PRICE
          </a>
          <Link
            className="build-door__link-prise"
            to={`/product-checkout/${this.props.product.id}`}
          >
            VIEW ON MY HOUSE
          </Link>
        </div>
      </div>
    )
  }
}

export default MostPopularDoorsItemList
