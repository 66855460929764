import React from "react";

class FooterPanelButton extends React.Component {
  render() {
    return (
      <div className="footer-panel__button">
        <button>PDF</button>
        <button>PRINT</button>
        <button>SAVE TO fAVORITES</button>
        <button>DELETE</button>
      </div>
    );
  }
}

export default FooterPanelButton;
