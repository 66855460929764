import React from "react";
import SimpleBar from "simplebar-react";
class MostPopularDoorsPanel extends React.Component {
  render() {
    return (
      <div className="main-box-content__inner-list ">
        <SimpleBar className="scrollable-area" forceVisible="y">
          <div className="main-box-content__inner__warpper">
            <div className="main-box-content__inner-list-listbox">
              <ul>
                {this.props.productCategories.map((productCategory, index) => {
                  return (
                    <li>
                      <a
                        key={`product_category_${index}`}
                        className={
                          this.props.category === productCategory.id
                            ? "active"
                            : ""
                        }
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          this.props.chooseCategory(productCategory);
                        }}
                      >
                        {productCategory.name}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </SimpleBar>
      </div>
    );
  }
}

export default MostPopularDoorsPanel;
