import React from "react";
import Main from "../layouts/Main";
import MainBoxAsite from "../GeneralComponents/MainBoxAsite";
import BackHomeSecond from "../BuildDoor/BackHomeSecond";
import MainTitleBlackText from "../GeneralComponents/MainTitleBlackText";
import MostPopularDoorsPanel from "./MostPopularDoorsPanel";
import MainBoxContentInnerContainer from "../ElementsConstructor/MainBoxContentInnerContainer";
import MostPopularDoorsItemList from "./MostPopularDoorsItemList";
import {
  loadProductCategories,
  loadProducts,
} from "./../../redux/actions/products";
import { connect } from "react-redux";
import { getAllHouses } from "../../redux/actions/houses";
import Loader from "../Loader/Loader";
import SeeOnMyHouse from "../SeeOnMyHouse/SeeOnMyHouse";
import { toPng } from "html-to-image";

class MostPopularDoorsSecond extends React.Component {
  state = {
    category: null,
    products: [],
    productCategories: [],
    is_loader: true,
    seeMyDoor: false,
    houses: [],
    activeHouse: {},
    myDoorEditor: false,
    activeProduct: {},
  };

  componentDidMount() {
    this.iniPage().then((response) => {
      this.setState({
        products: [...response.products],
        productCategories: [...response.productCategories],
        is_loader: false,
      });
    });
  }

  iniPage = async () => {
    let products = await this.props.loadProducts(this.state.category);
    let productCategories = await this.props.loadProductCategories();
    return {
      products: [...products.data],
      productCategories: [...productCategories.data],
    };
  };
  chooseCategory = (productCategory) => {
    this.setState(
      {
        is_loader: true,
      },
      () => {
        this.props.loadProducts(productCategory.id).then((response) => {
          this.setState({
            products: [...response.data],
            is_loader: false,
            category: productCategory.id,
          });
        });
      }
    );
  };

  loadHouses = () => {
    this.setState(
      {
        is_loader: true,
      },
      () => {
        this.props.getAllHouses().then((response) => {
          let activeHouse = response.data.length > 0 ? response.data[0] : {};
          this.setState({
            houses: [...response.data],
            activeHouse,
            is_loader: false,
          });
        });
      }
    );
  };

  showSeeMyDoor = (product) => {
    this.setState({
      seeMyDoor: true,
      activeProduct: { ...product },
    });
  };
  setActiveHouse = (house) => {
    this.setState({
      activeHouse: house,
    });
  };

  hideSeeMyDoor = () => {
    this.setState({
      seeMyDoor: false,
      activeHouse: {},
      myDoorEditor: false,
    });
  };

  downloadHouse = () => {
    this.setState({ is_loader: true }, () => {
      toPng(document.getElementById("html2png")).then((dataUrl) => {
        // console.log(dataUrl);
        const link = document.createElement("a");
        link.download = "my-door.png";
        link.href = dataUrl;
        link.click();
        this.setState({
          is_loader: false,
        });
      });
    });
  };

  setMyDoorEditor = (status) => {
    this.setState({
      myDoorEditor: status,
    });
  };

  render() {
    return (
      <>
        {this.state.seeMyDoor ? (
          <SeeOnMyHouse
            loadHouses={this.loadHouses}
            houses={this.state.houses}
            activeHouse={this.state.activeHouse}
            setActiveHouse={this.setActiveHouse}
            setSeeMyDoor={this.setSeeMyDoor}
            myDoorEditor={this.state.myDoorEditor}
            setMyDoorEditor={this.setMyDoorEditor}
            image={this.state.activeProduct.image}
            downloadHouse={this.downloadHouse}
            hideSeeMyDoor={this.hideSeeMyDoor}
          />
        ) : (
          <Main>
            <MainBoxAsite>
              <BackHomeSecond title="BACK TO DOOR STYLES" />
            </MainBoxAsite>
            <div className="main-box-content">
              <MainTitleBlackText
                classAdd="main-title-stage favourite-style"
                children="Most Popular Doors"
              />

              <div className="main-box-content__inner">
                <MostPopularDoorsPanel
                  productCategories={this.state.productCategories}
                  chooseCategory={this.chooseCategory}
                  category={this.state.category}
                />
                <MainBoxContentInnerContainer classAdd="overflow">
                  {this.state.products.map((product, index) => {
                    return (
                      <MostPopularDoorsItemList
                        key={index}
                        product={product}
                        showSeeMyDoor={this.showSeeMyDoor}
                      />
                    );
                  })}
                </MainBoxContentInnerContainer>
                <Loader status={this.state.is_loader} />
              </div>
            </div>
          </Main>
        )}
      </>
    );
  }
}
function mapStateToProps(state) {
  return {};
}
function mapDispatchToProps(dispatch) {
  return {
    loadProducts: (category) => {
      return dispatch(loadProducts(category));
    },
    loadProductCategories: () => {
      return dispatch(loadProductCategories());
    },
    getAllHouses: () => {
      return dispatch(getAllHouses());
    },
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MostPopularDoorsSecond);
