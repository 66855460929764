import axios from 'axios'

export function getOutsideDoorColor(door, brand) {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl
    let response = await axios.get(
      mainUrl + `/api/colors/outside-door/${door}/${brand}`
    )
    return response
  }
}

export function getInsideDoorColor(outside, brand) {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl
    let response = await axios.get(
      mainUrl + `/api/colors/inside-door/${outside}/${brand}`
    )
    return response
  }
}

export function getOutsideFrameColor(frame, brand) {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl
    let response = await axios.get(
      mainUrl + `/api/colors/outside-frame/${frame}/${brand}`
    )
    return response
  }
}

export function getInsideFrameColor(outside, brand) {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl
    let response = await axios.get(
      mainUrl + `/api/colors/inside-frame/${outside}/${brand}`
    )
    return response
  }
}

export function getDefaultColorsRequest(door, frame, brand) {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl
    let response = await axios.get(
      mainUrl + `/api/colors/defaults-colors/${door}/${frame}/${brand}`
    )
    return response
  }
}
