import React from 'react'
import { connect } from 'react-redux'

class TitleConstructor extends React.Component {
  render() {
    return (
      <div
        className={
          'summary__title' +
          ' ' +
          (this.props.classAdd !== undefined ? this.props.classAdd : '')
        }
        style={{
          backgroundColor: this.props.affiliate?.frontend_color ?? '#c7b299',
        }}
      >
        {this.props.title}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    affiliate: state.affiliate.data,
  }
}

export default connect(mapStateToProps)(TitleConstructor)
