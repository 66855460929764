import React from 'react'
import { connect } from 'react-redux'

function MainTitleBlackText(props) {
  return (
    <div
      className={
        'main-title' +
        ' ' +
        (props.classAdd !== undefined ? props.classAdd : '')
      }
    >
      <div className="container">
        <h1>{props.children}</h1>
      </div>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    affiliate: { ...state.affiliate.data },
  }
}
export default connect(mapStateToProps)(MainTitleBlackText)
