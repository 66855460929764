import React from 'react'
import TitleConstructor from '../../ElementsConstructor/TitleConstructor'
import MobileTitleConstructorSecond from '../../ElementsConstructor/MobileTitleConstructorSecond'
import FormFooterConstructorSecond from '../../ElementsConstructor/FormFooterConstructorSecond'
import BuildItemsConstructor from '../../ElementsConstructor/BuildItemsConstructor'
import BuildItemsInnerBoxConstructorMain from '../../ElementsConstructor/BuildItemsInnerBoxConstructorMain'
import FormFooterConstructorOutsideDoorColour from './FormFooterConstructorOutsideDoorColour'
import PreviewDoor from '../../ElementsConstructor/PreviewDoor'
import PreviewOutsideDoorColour from './PreviewOutsideDoorColour'

class OutsideDoorColour extends React.Component {
  componentDidMount() {
    this.props.setShowImageMobile(false)
    this.props.loadOutsideDoorColors()
  }

  render() {
    return (
      <>
        <TitleConstructor title="Outside door colour" classAdd="white" />
        <MobileTitleConstructorSecond title="Choose the outside colour of your door" />

        <BuildItemsConstructor>
          <BuildItemsInnerBoxConstructorMain
            classAdd="low-height"
            classAddBox="mobile col2"
          >
            {this.props.colors.map((color, index) => {
              let check = this.props.checkLayerInModelColor(
                this.props.partCategory,
                color,
                this.props.order
              )

              return (
                <div
                  className={
                    'build__item build__marker-2 ' +
                    (check.length > 0 ? 'active' : '')
                  }
                  key={`colors_${index}`}
                  onClick={() => {
                    this.props.chooseColorPart(
                      this.props.partCategory,
                      color,
                      this.props.order
                    )
                  }}
                >
                  <div
                    className="build__item-img"
                    style={{
                      backgroundColor: color.hex,
                    }}
                  />
                  <div className="build__item-name">{color.name}</div>
                </div>
              )
            })}
          </BuildItemsInnerBoxConstructorMain>
        </BuildItemsConstructor>
        <FormFooterConstructorOutsideDoorColour
          link="/frame-style"
          greenButtonHandler={this.props.greenButtonHandler}
          backButtonHandler={this.props.backButtonHandler}
          setSeeMyDoor={this.props.setSeeMyDoor}
          setShowImageMobile={this.props.setShowImageMobile}
          showImageMobile={this.props.showImageMobile}
        />
        <PreviewOutsideDoorColour
          image={this.props.image}
          showImageMobile={this.props.showImageMobile}
          setShowImageMobile={this.props.setShowImageMobile}
          setOrder={this.props.setOrder}
          backButtonHandler={this.props.backButtonHandler}
          order={this.props.order}
          scale={this.props.scale}
          setScale={this.props.setScale}
        />
      </>
    )
  }
}

export default OutsideDoorColour
