import React from 'react'
import SummaryPanelMobile from '../ElementsConstructor/SummaryPanelMobile'
class LeftPartConstructor extends React.Component {
  render() {
    return (
      <div className="summary__con-inner">
        <div className="summary__con ">
          <SummaryPanelMobile
            page={this.props.page}
            navigateConstructorNav={this.props.navigateConstructorNav}
            setPage={this.props.setPage}
          />
          {this.props.children}
        </div>
      </div>
    )
  }
}

export default LeftPartConstructor
