import React from "react";
import FooterPanelButton from "./FooterPanelButton";

class FooterPanelSecond extends React.Component {
  render() {
    return (
      <div className="footer-panel">
        <div className="container-fluid">
          <div className="footer-panel__inner">
            <FooterPanelButton></FooterPanelButton>
            <div className="footer-panel__function">
              <div className="footer-panel__function-btn">
                <figure>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8.663"
                    height="6.309"
                    viewBox="0 0 8.663 6.309"
                  >
                    <g
                      id="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C_22"
                      data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 22"
                      transform="translate(-999.081 -1839.866)"
                    >
                      <path
                        id="\u041A\u043E\u043D\u0442\u0443\u0440_25"
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 25"
                        d="M1007.543,1843.221h-8.261a.2.2,0,1,1,0-.4h8.261a.2.2,0,0,1,0,.4Z"
                        fill="#fff"
                      />
                      <path
                        id="\u041A\u043E\u043D\u0442\u0443\u0440_26"
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 26"
                        d="M1007.543,1843.221a.2.2,0,0,1-.141-.059l-2.955-2.954a.2.2,0,0,1,.284-.283l2.954,2.954a.2.2,0,0,1-.142.342Z"
                        fill="#fff"
                      />
                      <path
                        id="\u041A\u043E\u043D\u0442\u0443\u0440_27"
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 27"
                        d="M1004.589,1846.175a.2.2,0,0,1-.142-.342l2.954-2.954a.2.2,0,0,1,.283.283l-2.953,2.955A.2.2,0,0,1,1004.589,1846.175Z"
                        fill="#fff"
                      />
                    </g>
                  </svg>
                </figure>
                INTERNAL VIEW
              </div>
              <div className="footer-panel__function-btn">
                <figure>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8.662"
                    height="8.662"
                    viewBox="0 0 8.662 8.662"
                  >
                    <g
                      id="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C_23"
                      data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 23"
                      transform="translate(-1079.779 -1838.69)"
                    >
                      <path
                        id="\u041A\u043E\u043D\u0442\u0443\u0440_28"
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 28"
                        d="M1083.91,1847.151v-8.261a.2.2,0,0,1,.4,0v8.261a.2.2,0,1,1-.4,0Z"
                        fill="#fff"
                      />
                      <path
                        id="\u041A\u043E\u043D\u0442\u0443\u0440_29"
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 29"
                        d="M1079.98,1842.82h8.261a.2.2,0,0,1,0,.4h-8.261a.2.2,0,1,1,0-.4Z"
                        fill="#fff"
                      />
                    </g>
                  </svg>
                </figure>
                ZOOM VIEW
              </div>
              <div className="footer-panel__function-btn">
                <figure>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8.103"
                    height="8.103"
                    viewBox="0 0 8.103 8.103"
                  >
                    <path
                      id="\u041A\u043E\u043D\u0442\u0443\u0440_30"
                      data-name="\u041A\u043E\u043D\u0442\u0443\u0440 30"
                      d="M1179.906,1847.1a4.052,4.052,0,1,1,4.051-4.051A4.056,4.056,0,0,1,1179.906,1847.1Zm0-7.635a3.583,3.583,0,1,0,3.583,3.584A3.588,3.588,0,0,0,1179.906,1839.468Z"
                      transform="translate(-1175.854 -1839)"
                      fill="#fff"
                    />
                  </svg>
                </figure>
                CHANGE BACKGROUND
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FooterPanelSecond;
