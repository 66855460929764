import React from 'react'
import DoorStyleSecond from '../DoorStyleSecond/DoorStyleSecond'
import LeftPartConstructor from '../../ElementsConstructor/LeftPartConstructor'
import RightPartConstructor from '../../ElementsConstructor/RightPartConstructor'
import ImageConstructor from '../../ElementsConstructor/ImageConstructor'
import FooterImagePanelConstructor from '../../ElementsConstructor/FooterImagePanelConstructor'
import FrameStyleSecond from '../FrameStyleSecond/FrameStyleSecond'
import OutsideDoorColour from '../OutsideDoorColour/OutsideDoorColour'
import InsideDoorColour from '../InsideDoorColour/InsideDoorColour'
import OutsideFrameColour from '../OutsideFrameColour/OutsideFrameColour'
import InsideFrameColour from '../InsideFrameColour/InsideFrameColour'
import GlassDesign from '../GlassDesign/GlassDesign'
import FurnitureTypeHandle from '../FurnitureTypeHandle/FurnitureTypeHandle'
import FurnitureColorHandle from '../FurnitureColorHandle/FurnitureColorHandle'
import FurnitureTypeKnocker from '../FurnitureTypeKnocker/FurnitureTypeKnocker'
import FurnitureTypeLetterplate from '../FurnitureTypeLetterplate/FurnitureTypeLetterplate'
import FurnitureLeftOrRight from '../FurnitureLeftOrRight/FurnitureLeftOrRight'
import MyDreamDoor from '../MyDreamDoor/MyDreamDoor'
import ThankYou from '../ThankYou/ThankYou'
import { withRouter } from 'react-router-dom'
import FurnitureGroup from '../FurnitureGroup/FurnitureGroup'
import FurnitureWreath from '../FurnitureWreath/FurnitureWreath'

function ConsumerFlowContainer(props) {
  return (
    <>
      <LeftPartConstructor
        page={props.page}
        navigateConstructorNav={props.navigateConstructorNav}
        setPage={props.setPage}
      >
        {props.page === 'door_style' ? (
          <DoorStyleSecond
            brand={props.brand}
            brandTitle={props.brandTitle}
            greenButtonHandler={props.nextDoorStyle}
            model={props.model}
            checkLayerInModel={props.checkLayerInModel}
            chooseComponent={props.chooseComponent}
            parts={props.filterParts('door_style')}
            backButtonHandler={() => {}}
            setSeeMyDoor={props.setSeeMyDoor}
            setShowImageMobile={props.setShowImageMobile}
            image={props.image}
            showImageMobile={props.showImageMobile}
            setOrder={props.setOrder}
            order={props.order}
            scale={props.scale}
            setScale={props.setScale}
          />
        ) : null}
        {props.page === 'frame_style' ? (
          <FrameStyleSecond
            greenButtonHandler={props.nextFrameStyle}
            model={props.model}
            checkLayerInModel={props.checkLayerInModel}
            chooseComponent={props.chooseComponent}
            parts={props.filterParts('frame_style')}
            backButtonHandler={() => {
              props.backButtonHandler('door_style')
            }}
            setSeeMyDoor={props.setSeeMyDoor}
            setShowImageMobile={props.setShowImageMobile}
            image={props.image}
            showImageMobile={props.showImageMobile}
            setOrder={props.setOrder}
            order={props.order}
            scale={props.scale}
            setScale={props.setScale}
            brand={props.brand}
          />
        ) : null}
        {props.page === 'outside-door-color' ? (
          <OutsideDoorColour
            greenButtonHandler={props.nextOutsideDoorColor}
            colors={props.outsideDoorColours}
            chooseColorPart={props.chooseColorPart}
            checkColorInModel={props.checkColorInModel}
            checkLayerInModelColor={props.checkLayerInModelColor}
            partCategory={'door_style'}
            order={'external'}
            backButtonHandler={() => {
              props.backButtonHandler('frame_style')
            }}
            setSeeMyDoor={props.setSeeMyDoor}
            setShowImageMobile={props.setShowImageMobile}
            image={props.image}
            showImageMobile={props.showImageMobile}
            setOrder={props.setOrder}
            scale={props.scale}
            setScale={props.setScale}
            outsideDoorColours={props.outsideDoorColours}
            loadOutsideDoorColors={props.loadOutsideDoorColors}
          />
        ) : null}
        {props.page === 'outside-frame-color' ? (
          <OutsideFrameColour
            greenButtonHandler={props.nextOutsideFrameColor}
            colors={props.outsideFrameColours}
            chooseColorPart={props.chooseColorPart}
            checkColorInModel={props.checkColorInModel}
            checkLayerInModelColor={props.checkLayerInModelColor}
            partCategory={'frame_style'}
            order={'external'}
            setOrder={props.setOrder}
            backButtonHandler={() => {
              props.backButtonHandler('outside-door-color')
            }}
            setSeeMyDoor={props.setSeeMyDoor}
            setShowImageMobile={props.setShowImageMobile}
            image={props.image}
            showImageMobile={props.showImageMobile}
            scale={props.scale}
            setScale={props.setScale}
            outsideFrameColours={props.outsideFrameColours}
            loadOutsideFrameColors={props.loadOutsideFrameColors}
          />
        ) : null}
        {props.page === 'inside-door-color' ? (
          <InsideDoorColour
            greenButtonHandler={props.nextInsideDoorColor}
            colors={props.insideDoorColours}
            chooseColorPart={props.chooseColorPart}
            checkColorInModel={props.checkColorInModel}
            checkLayerInModelColor={props.checkLayerInModelColor}
            partCategory={'door_style'}
            order={'internal'}
            setOrder={props.setOrder}
            backButtonHandler={() => {
              props.backButtonHandler('outside-frame-color')
            }}
            setSeeMyDoor={props.setSeeMyDoor}
            setShowImageMobile={props.setShowImageMobile}
            image={props.image}
            showImageMobile={props.showImageMobile}
            scale={props.scale}
            setScale={props.setScale}
            insideDoorColours={props.insideDoorColours}
            loadInsideDoorColors={props.loadInsideDoorColors}
          />
        ) : null}
        {props.page === 'inside-frame-color' ? (
          <InsideFrameColour
            greenButtonHandler={props.nextInsideFrameColor}
            colors={props.insideFrameColours}
            chooseColorPart={props.chooseColorPart}
            checkColorInModel={props.checkColorInModel}
            checkLayerInModelColor={props.checkLayerInModelColor}
            partCategory={'frame_style'}
            order={'internal'}
            setOrder={props.setOrder}
            backButtonHandler={() => {
              props.backButtonHandler('inside-door-color')
            }}
            setSeeMyDoor={props.setSeeMyDoor}
            setShowImageMobile={props.setShowImageMobile}
            image={props.image}
            showImageMobile={props.showImageMobile}
            scale={props.scale}
            setScale={props.setScale}
            insideFrameColours={props.insideFrameColours}
            loadInsideFrameColors={props.loadInsideFrameColors}
          />
        ) : null}
        {props.page === 'glass-design' ? (
          <GlassDesign
            greenButtonHandler={props.nextGlassDesign}
            chooseGlassDouble={props.chooseGlassDouble}
            checkLayerInModel={props.checkLayerInModel}
            model={props.model}
            setOrder={props.setOrder}
            loadGlassByModel={props.loadGlassByModel}
            glasses={props.glasses}
            glassActive={props.glassActive}
            backButtonHandler={() => {
              props.backButtonHandler('inside-frame-color')
            }}
            setSeeMyDoor={props.setSeeMyDoor}
            setShowImageMobile={props.setShowImageMobile}
            image={props.image}
            showImageMobile={props.showImageMobile}
            order={props.order}
            scale={props.scale}
            setScale={props.setScale}
          />
        ) : null}{' '}
        {/*{props.page === "glass-frame" ? (*/}
        {/*  <GlassFrame*/}
        {/*    backButtonHandler={() => {*/}
        {/*      props.backButtonHandler("glass-design");*/}
        {/*    }}*/}
        {/*    findLayer={props.findLayer}*/}
        {/*    frameGlasses={props.frameGlasses}*/}
        {/*    model={props.model}*/}
        {/*    chooseFrameGlass={props.chooseFrameGlass}*/}
        {/*    loadFrameGlasses={props.loadFrameGlasses}*/}
        {/*    greenButtonHandler={props.nextGlassFrame}*/}
        {/*    setSeeMyDoor={props.setSeeMyDoor}*/}
        {/*    setShowImageMobile={props.setShowImageMobile}*/}
        {/*  />*/}
        {/*) : null}*/}
        {props.page === 'furniture-group' ? (
          <FurnitureGroup
            backButtonHandler={() => {
              props.backButtonHandler('glass-design')
            }}
            greenButtonHandler={props.nextFurnitureGroup}
            greenNextButtonHandler={props.nextFurnitureSideHinges}
            setShowImageMobile={props.setShowImageMobile}
            loadFurnitureGroup={props.loadFurnitureGroup}
            furnitureGroups={props.furnitureGroups}
            chooseFurnitureGroup={props.chooseFurnitureGroup}
            browseAllFurniture={props.browseAllFurniture}
            image={props.image}
            showImageMobile={props.showImageMobile}
            setOrder={props.setOrder}
            order={props.order}
            scale={props.scale}
            setScale={props.setScale}
          />
        ) : null}
        {props.page === 'furniture-type-handle' ? (
          <FurnitureTypeHandle
            model={props.model}
            greenButtonHandler={props.nextFurnitureTypeHandle}
            loadHandelBrands={props.loadHandelBrands}
            handelBrands={props.handelBrands}
            activeHandleBrand={props.activeHandleBrand}
            chooseHandelBrand={props.chooseHandelBrand}
            isHandleBrandError={props.isHandleBrandError}
            setIsHandleBrandError={props.setIsHandleBrandError}
            backButtonHandler={() => {
              props.backButtonHandler('glass-design')
            }}
            setSeeMyDoor={props.setSeeMyDoor}
            setShowImageMobile={props.setShowImageMobile}
            image={props.image}
            showImageMobile={props.showImageMobile}
            setOrder={props.setOrder}
            order={props.order}
            scale={props.scale}
            setScale={props.setScale}
          />
        ) : null}
        {props.page === 'furniture-color-handle' ? (
          <FurnitureColorHandle
            greenButtonHandler={props.nextFurnitureColorHandle}
            handles={props.handles}
            model={props.model}
            activeHandleBrand={props.activeHandleBrand}
            checkLayerInModel={props.checkLayerInModel}
            chooseComponent={props.chooseComponent}
            isHandleError={props.isHandleError}
            handle={props.handle}
            setIsHandleError={props.setIsHandleError}
            backButtonHandler={() => {
              props.backButtonHandler('furniture-type-handle')
            }}
            setSeeMyDoor={props.setSeeMyDoor}
            setShowImageMobile={props.setShowImageMobile}
            image={props.image}
            showImageMobile={props.showImageMobile}
            setOrder={props.setOrder}
            order={props.order}
            scale={props.scale}
            setScale={props.setScale}
          />
        ) : null}
        {props.page === 'furniture-wreath' ? (
          <FurnitureWreath
            greenButtonHandler={props.nextFurnitureWreath}
            wreaths={props.wreaths}
            loadWreaths={props.loadWreaths}
            setShowImageMobile={props.setShowImageMobile}
            image={props.image}
            showImageMobile={props.showImageMobile}
            chooseComponent={props.chooseComponent}
            checkLayerInModel={props.checkLayerInModel}
            backButtonHandler={() => {
              props.backButtonHandler('furniture-color-handle')
            }}
          />
        ) : null}
        {props.page === 'furniture-type-knocker' ? (
          <FurnitureTypeKnocker
            greenButtonHandler={props.nextFurnitureTypeKnocker}
            model={props.model}
            checkLayerInModel={props.checkLayerInModel}
            chooseComponent={props.chooseComponent}
            knockers={props.knockers}
            loadKnockers={props.loadKnockers}
            backButtonHandler={() => {
              props.backButtonHandler('furniture-wreath')
            }}
            setSeeMyDoor={props.setSeeMyDoor}
            setShowImageMobile={props.setShowImageMobile}
            image={props.image}
            showImageMobile={props.showImageMobile}
            setOrder={props.setOrder}
            order={props.order}
            scale={props.scale}
            setScale={props.setScale}
          />
        ) : null}
        {props.page === 'furniture-type-letterplate' ? (
          <FurnitureTypeLetterplate
            greenButtonHandler={props.nextFurnitureTypeLetterplate}
            checkLayerInModel={props.checkLayerInModel}
            chooseComponent={props.chooseComponent}
            letterplates={props.letterplates}
            loadLetterplates={props.loadLetterplates}
            backButtonHandler={() => {
              props.backButtonHandler('furniture-type-knocker')
            }}
            setSeeMyDoor={props.setSeeMyDoor}
            setShowImageMobile={props.setShowImageMobile}
            image={props.image}
            showImageMobile={props.showImageMobile}
            setOrder={props.setOrder}
            order={props.order}
            scale={props.scale}
            setScale={props.setScale}
          />
        ) : null}
        {props.page === 'furniture-side-hinges' ? (
          <FurnitureLeftOrRight
            greenButtonHandler={props.nextFurnitureSideHinges}
            side={props.side}
            changeSide={props.changeSide}
            backButtonHandler={() => {
              props.backButtonHandler('furniture-type-letterplate')
            }}
            loadLeftOrRight={props.loadLeftOrRight}
            setSeeMyDoor={props.setSeeMyDoor}
            setShowImageMobile={props.setShowImageMobile}
            image={props.image}
            showImageMobile={props.showImageMobile}
            setOrder={props.setOrder}
            order={props.order}
            scale={props.scale}
            setScale={props.setScale}
            setLoader={props.setLoader}
            model={props.model}
          />
        ) : null}
        {props.page === 'my-dream-door' &&
        props.match.path === '/consumer-flow-constructor/:id?' ? (
          <MyDreamDoor
            greenButtonHandler={props.nextMyDreamDoor}
            backButtonHandler={() => {
              props.backButtonHandler('furniture-side-hinges')
            }}
            nameOnChangeHandler={props.nameOnChangeHandler}
            addressNumber1OnChangeHandler={props.addressNumber1OnChangeHandler}
            addressNumber2OnChangeHandler={props.addressNumber2OnChangeHandler}
            countyOnChangeHandler={props.countyOnChangeHandler}
            postcodeOnChangeHandler={props.postcodeOnChangeHandler}
            mobileOnChangeHandler={props.mobileOnChangeHandler}
            emailOnChangeHandler={props.emailOnChangeHandler}
            formData={props.formData}
            saveOrder={props.saveOrder}
            setSeeMyDoor={props.setSeeMyDoor}
          />
        ) : null}
        {props.page === 'my-dream-door' &&
        props.match.path ===
          '/admin-constructor/:brand/(partner-id)?/:iframePartnerPath?' ? (
          <MyDreamDoor
            greenButtonHandler={props.nextMyDreamDoor}
            backButtonHandler={() => {
              props.backButtonHandler('furniture-side-hinges')
            }}
            model={props.model}
            affiliate={props.affiliate}
            nameOnChangeHandler={props.nameOnChangeHandler}
            addressNumber1OnChangeHandler={props.addressNumber1OnChangeHandler}
            addressNumber2OnChangeHandler={props.addressNumber2OnChangeHandler}
            countyOnChangeHandler={props.countyOnChangeHandler}
            postcodeOnChangeHandler={props.postcodeOnChangeHandler}
            mobileOnChangeHandler={props.mobileOnChangeHandler}
            emailOnChangeHandler={props.emailOnChangeHandler}
            couponOnChangeHandler={props.couponOnChangeHandler}
            formData={props.formData}
            saveOrder={props.saveOrder}
            image={props.image}
            setSeeMyDoor={props.setSeeMyDoor}
            setShowImageMobile={props.setShowImageMobile}
          />
        ) : null}
        {props.page === 'thank-you' ? (
          <ThankYou setSeeMyDoor={props.setSeeMyDoor} image={props.image} />
        ) : null}
      </LeftPartConstructor>

      <RightPartConstructor>
        <ImageConstructor
          image={props.image}
          scale={props.scale}
          page={props.page}
          backgroundColor={props.backgroundColor}
          greenButtonHandler={props.greenButtonHandler}
          nextDoorStyle={props.nextDoorStyle}
          nextFrameStyle={props.nextFrameStyle}
          nextOutsideDoorColor={props.nextOutsideDoorColor}
          nextOutsideFrameColor={props.nextOutsideFrameColor}
          nextInsideDoorColor={props.nextInsideDoorColor}
          nextInsideFrameColor={props.nextInsideFrameColor}
          nextGlassDesign={props.nextGlassDesign}
          nextFurnitureTypeHandle={props.nextFurnitureTypeHandle}
          nextFurnitureColorHandle={props.nextFurnitureColorHandle}
          nextFurnitureTypeKnocker={props.nextFurnitureTypeKnocker}
          nextFurnitureWreath={props.nextFurnitureWreath}
          nextFurnitureTypeLetterplate={props.nextFurnitureTypeLetterplate}
          nextFurnitureSideHinges={props.nextFurnitureSideHinges}
          nextFurnitureGroup={props.nextFurnitureGroup}
        />
        <FooterImagePanelConstructor
          classAdd="disabled"
          setOrder={props.setOrder}
          order={props.order}
          setScale={props.setScale}
          setBackgroundColor={props.setBackgroundColor}
          setShowImageMobile={props.setShowImageMobile}
        />
      </RightPartConstructor>
    </>
  )
}
export default withRouter(ConsumerFlowContainer)
