import React, { Component } from "react";
import Main from "../layouts/Main";
import MainBoxAsite from "../GeneralComponents/MainBoxAsite";
import MainBoxHome from "../layouts/MainBoxHome";
import LoginWrapper from "./LoginWrapper";
import { connect } from "react-redux";
import { login, setUserToken } from "../../redux/actions/users";
import { NotificationManager } from "react-notifications";

class Login extends Component {
  state = {
    formData: {
      email: "",
      password: "",
    },
  };

  onChangeEmail = (event) => {
    this.setState({
      formData: {
        ...this.state.formData,
        email: event.target.value,
      },
    });
  };
  onChangePassword = (event) => {
    this.setState({
      formData: {
        ...this.state.formData,
        password: event.target.value,
      },
    });
  };

  login = () => {
    this.props
      .login(this.state.formData)
      .then((response) => {
        NotificationManager.success("login success");
        this.props.setUserToken(response.data.access_token);
      })
      .catch((error) => {
        NotificationManager.error("login error");
      });
  };

  render() {
    return (
      <Main header={true}>
        <MainBoxAsite></MainBoxAsite>
        <MainBoxHome>
          <LoginWrapper
            formData={this.state.formData}
            onChangeEmail={this.onChangeEmail}
            onChangePassword={this.onChangePassword}
            login={this.login}
          />
        </MainBoxHome>
      </Main>
    );
  }
}

function mapStateToProps(state) {
  return {};
}
function mapDispatchToProps(dispatch) {
  return {
    login: (formData) => {
      return dispatch(login(formData));
    },
    setUserToken: (token) => {
      return dispatch(setUserToken(token));
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Login);
