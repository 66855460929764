import React from "react";
import Main from "../layouts/Main";
import MainBoxAsite from "../GeneralComponents/MainBoxAsite";
import BackHomeSecond from "../BuildDoor/BackHomeSecond";
import MainTitleBlackText from "../GeneralComponents/MainTitleBlackText";
import EnergyEfficiencyListPanel from "./EnergyEfficiencyListPanel";

import SimpleBar from "simplebar-react";
import FavouriteStyleFooter from "../ChooseYourFavouriteStyle/FavouriteStyleFooter";
class SecurityFeatures extends React.Component {
  // componentDidMount() {
  // 	let hash = window.location.hash;
  // 	let element = document.getElementById(hash);
  // 	element.scrollIntoView({ behavior: "smooth", block: "start" });
  // }

  // componentDidUpdate(prevProps, prevState, snapshot) {
  // 	let hash = window.location.hash;
  // 	let element = document.getElementById(hash);
  // 	element.scrollIntoView({ behavior: "smooth", block: "start" });
  // }

  render() {
    return (
      <Main header={true}>
        <MainBoxAsite>
          <BackHomeSecond title="BACK TO DOOR STYLES" />
        </MainBoxAsite>
        <div className="main-box-content">
          <MainTitleBlackText
            classAdd="main-title-stage favourite-style"
            children="Security Features"
          />

          <div className="main-box-content__inner column">
            <div className="energyEfficiency__wrapper">
              <SimpleBar className="scrollable-area" forceVisible="y">
                <div className="main-box-content__inner-container ">
                  <EnergyEfficiencyListPanel />
                  <div className="efficiency-info__wrapper">
                    <div className="efficiency-info__first">
                      <div className="efficiency-info__first-item">
                        <div className="efficiency-info__first-item-con">
                          <div className="efficiency-info__first-item-con__inner">
                            <div className="efficiency-info-title">
                              You’re in safe hands
                            </div>
                            <div className="efficiency-info__first-item-img">
                              <img
                                src={
                                  require("../../assets/images/energy-efficiency/Security-Features-1.jpg")
                                    
                                }
                                alt=""
                              />
                            </div>
                            <div className="efficiency-info-text-inner">
                              <div className="efficiency-info-text">
                                We’ve thought of everything that can go wrong
                                with your door. Then made sure it won’t. From
                                police-approved locks to rhino-resistant frames,
                                you’re in safe hands.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="efficiency-info__first-item height">
                        <div className="efficiency-info__first-item-img">
                          <img
                            src={
                              require("../../assets/images/energy-efficiency/Security-Features-2.jpg")
                               
                            }
                            alt=""
                          />
                        </div>
                        <div className="efficiency-info__first-item-img">
                          <img
                            src={
                              require("../../assets/images/energy-efficiency/Security-Features-3.jpg")
                              
                            }
                            alt=""
                          />
                        </div>
                        <div className="efficiency-info__first-item-img">
                          <img
                            src={
                              require("../../assets/images/energy-efficiency/Security-Features-4.jpg")
                               
                            }
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="efficiency-info__second">
                      <div className="efficiency-info__second__inner">
                        <div className="efficiency-info-text">
                          <p>A great night’s sleep</p>
                          <div className="efficiency-info-text-mb">
                            <p>
                              Rest easy - our doors are as secure as they come.
                              Good to know, whether you’re tucked up at home or
                              away for the night.
                            </p>
                            <p>
                              Every Apeer door comes with a multi-point locking
                              system as well as a super-strong, highly
                              insulating polyurethane core that far exceeds all
                              Police-approved security standards.
                            </p>
                          </div>

                          <p>Strength from within</p>
                          <p>
                            Inside every Apeer 70mm door there’s a super-strong
                            steel and metal reinforcing that forms part of the
                            highly insulating polyurethane core. And with a
                            vertical steel bar running top to bottom along the
                            inside edge, our doors far exceed Police-approved
                            security standards.
                          </p>
                        </div>
                      </div>
                      <div className="efficiency-info__second-img-box height">
                        <div className="efficiency-info__second-img">
                          <img
                            src={
                              require("../../assets/images/energy-efficiency/Security-Features-5.jpg")
                              
                            }
                            alt=""
                          />
                        </div>
                        <div className="efficiency-info__second-img">
                          <img
                            src={
                              require("../../assets/images/energy-efficiency/Security-Features-6.jpg")
                               
                            }
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="efficiency-info__third">
                      <div className="efficiency-info-text">
                        <p>We’ve got your back. And your front.</p>
                        <div className="efficiency-info-text-mb">
                          <p>
                            Install an Apeer door and you’ll have the weight of
                            our entire design team and security specialists
                            protecting you and your home from intruders. Our
                            doors comfortably exceed all industry standard
                            impact and cut-through tests.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <FavouriteStyleFooter>
                    <div className="favourite-style-footer-backbtn">
                      <a href="/"> {"< BACK"} </a>
                    </div>
                  </FavouriteStyleFooter>
                </div>
              </SimpleBar>
            </div>
          </div>
        </div>
      </Main>
    );
  }
}

export default SecurityFeatures;
