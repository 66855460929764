import React from 'react'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router-dom'

class BackHomeThird extends React.Component {
  render() {
    return (
      <div className="back-home">
        <a href={this.props.link}>
          <div className="back-home-img">
            <img
              src={require('../../assets/images/arrow-white.svg').default}
              alt=""
            />
          </div>
          <div className="back-home-title br-width">{this.props.title}</div>
        </a>
      </div>
    )
  }
}

export default withRouter(BackHomeThird)
