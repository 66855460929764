import React, { Component } from 'react'
import TitleConstructor from '../../ElementsConstructor/TitleConstructor'
import MobileTitleConstructorSecond from '../../ElementsConstructor/MobileTitleConstructorSecond'
import BuildItemsConstructor from '../../ElementsConstructor/BuildItemsConstructor'
import SimpleBar from 'simplebar-react'
import FormFooterConstructorFurnitureGroup from './FormFooterConstructorFurnitureGroup'
import PreviewFurnitureGroup from './PreviewFurnitureGroup'

class FurnitureGroup extends Component {
  componentDidMount() {
    this.props.loadFurnitureGroup()
  }

  render() {
    return (
      <>
        <TitleConstructor title="Furniture" classAdd="white" />
        <MobileTitleConstructorSecond title="Choose the look you love, or browse our full range of furniture" />

        <BuildItemsConstructor>
          <SimpleBar className="scrollable-area" forceVisible="y">
            <div className="furniture-stack__wrapper">
              <div className="furniture-stack-box">
                {this.props.furnitureGroups.map((group, index) => {
                  return (
                    <div
                      className="furniture-stack-box-item"
                      key={`furniture_group_${index}`}
                    >
                      <div className="furniture-stack-item-info">
                        <div className="furniture-stack-item-info-title">
                          {group.name}
                        </div>
                        <div className="furniture-stack-item-info-btn">
                          <button
                            type="button"
                            onClick={() => {
                              this.props.chooseFurnitureGroup(group)
                            }}
                          >
                            ADD TO <br></br> MY DOOR
                          </button>
                        </div>
                      </div>
                      <div className="furniture-stack-box-item-gallery">
                        <div className="furniture-stack-box-item-gallery-img">
                          <img
                            src={
                              require('../../../assets/images/furniture-stack/furniture-stack-1.jpg')
                              
                            }
                            alt=""
                          />
                        </div>
                        <div className="furniture-stack-box-item-gallery-img">
                          <img
                            src={
                              require('../../../assets/images/furniture-stack/furniture-stack-2.jpg')
                               
                            }
                            alt=""
                          />
                        </div>
                        <div className="furniture-stack-box-item-gallery-img">
                          <img
                            src={
                              require('../../../assets/images/furniture-stack/furniture-stack-3.jpg')
                              
                            }
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  )
                })}

                {/*<div className="furniture-stack-box-item">*/}
                {/*  <div className="furniture-stack-item-info">*/}
                {/*    <div className="furniture-stack-item-info-title">*/}
                {/*      Traditional Furniture*/}
                {/*    </div>*/}
                {/*    <div className="furniture-stack-item-info-btn">*/}
                {/*      <button type="button">*/}
                {/*        ADD TO <br></br> MY DOOR*/}
                {/*      </button>*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*  <div className="furniture-stack-box-item-gallery second">*/}
                {/*    <div className="furniture-stack-box-item-gallery-img">*/}
                {/*      <img*/}
                {/*        src={*/}
                {/*          require("../../../assets/images/furniture-stack/furniture-stack-4.jpg")*/}
                {/*            .default*/}
                {/*        }*/}
                {/*        alt=""*/}
                {/*      />*/}
                {/*    </div>*/}
                {/*    <div className="furniture-stack-box-item-gallery-img">*/}
                {/*      <img*/}
                {/*        src={*/}
                {/*          require("../../../assets/images/furniture-stack/furniture-stack-5.jpg")*/}
                {/*            .default*/}
                {/*        }*/}
                {/*        alt=""*/}
                {/*      />*/}
                {/*    </div>*/}
                {/*    <div className="furniture-stack-box-item-gallery-img">*/}
                {/*      <img*/}
                {/*        src={*/}
                {/*          require("../../../assets/images/furniture-stack/furniture-stack-6.jpg")*/}
                {/*            .default*/}
                {/*        }*/}
                {/*        alt=""*/}
                {/*      />*/}
                {/*    </div>*/}
                {/*    <div className="furniture-stack-box-item-gallery-img">*/}
                {/*      <img*/}
                {/*        src={*/}
                {/*          require("../../../assets/images/furniture-stack/furniture-stack-7.jpg")*/}
                {/*            .default*/}
                {/*        }*/}
                {/*        alt=""*/}
                {/*      />*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*</div>*/}
              </div>
              <div className="furniture-stack-btn">
                <button
                  className="global-btn"
                  type="button"
                  onClick={() => {
                    this.props.browseAllFurniture()
                  }}
                >
                  {'BROWSE ALL FURNITURE >'}
                </button>
              </div>
            </div>
          </SimpleBar>
        </BuildItemsConstructor>
        <FormFooterConstructorFurnitureGroup
          link="/frame-style-second"
          greenButtonHandler={this.props.greenButtonHandler}
          backButtonHandler={this.props.backButtonHandler}
          setSeeMyDoor={this.props.setSeeMyDoor}
          setShowImageMobile={this.props.setShowImageMobile}
          showImageMobile={this.props.showImageMobile}
          greenNextButtonHandler={this.props.greenNextButtonHandler}
        />
        <PreviewFurnitureGroup
          image={this.props.image}
          showImageMobile={this.props.showImageMobile}
          setShowImageMobile={this.props.setShowImageMobile}
          setOrder={this.props.setOrder}
          order={this.props.order}
          scale={this.props.scale}
          setScale={this.props.setScale}
          backButtonHandler={this.props.backButtonHandler}
        />
      </>
    )
  }
}
export default FurnitureGroup
