import React from "react";
import Main from "../layouts/Main";
import MainBoxAsite from "../GeneralComponents/MainBoxAsite";
import BackHomeSecond from "../BuildDoor/BackHomeSecond";
import MainTitleBlackText from "../GeneralComponents/MainTitleBlackText";
import EnergyEfficiencyListPanel from "./EnergyEfficiencyListPanel";

import SimpleBar from "simplebar-react";
import FavouriteStyleFooter from "../ChooseYourFavouriteStyle/FavouriteStyleFooter";
class ColourChoices extends React.Component {
  // componentDidMount() {
  // 	let hash = window.location.hash;
  // 	let element = document.getElementById(hash);
  // 	element.scrollIntoView({ behavior: "smooth", block: "start" });
  // }

  // componentDidUpdate(prevProps, prevState, snapshot) {
  // 	let hash = window.location.hash;
  // 	let element = document.getElementById(hash);
  // 	element.scrollIntoView({ behavior: "smooth", block: "start" });
  // }

  render() {
    return (
      <Main header={true}>
        <MainBoxAsite>
          <BackHomeSecond title="BACK TO DOOR STYLES" />
        </MainBoxAsite>
        <div className="main-box-content">
          <MainTitleBlackText
            classAdd="main-title-stage favourite-style"
            children="Colour Choices"
          />

          <div className="main-box-content__inner column">
            <div className="energyEfficiency__wrapper">
              <SimpleBar className="scrollable-area" forceVisible="y">
                <div className="main-box-content__inner-container ">
                  <EnergyEfficiencyListPanel />
                  <div className="efficiency-info__wrapper">
                    <div className="efficiency-info__first">
                      <div className="efficiency-info__first-item">
                        <div className="efficiency-info__first-item-con">
                          <div className="efficiency-info__first-item-con__inner">
                            <div className="efficiency-info-title">
                              Doors with a unique personality and character:
                              yours.
                            </div>
                            <div className="efficiency-info__first-item-img">
                              <img
                                src={
                                  require("../../assets/images/energy-efficiency/Colour-Choices-1.jpg")
                                  
                                }
                                alt=""
                              />
                            </div>
                            <div className="efficiency-info-text-inner">
                              <div className="efficiency-info-text">
                                Your front door is the focal point of your
                                home’s façade. But it’s more than just an
                                entrance. It speaks to the world on your behalf.
                                It reflects your personality and stands for the
                                same things you do. If that’s impeccable taste,
                                craftsmanship and protecting your family from
                                mother nature’s tantrums you’ve come to the
                                right place. And if it’s also about standing out
                                from the crowd, our standard colour choices or
                                colour matching options are right up your
                                street.
                              </div>
                            </div>
                            <div className="efficiency-info__first-item-img order-none">
                              <img
                                src={
                                  require("../../assets/images/energy-efficiency/Colour-Choices-2.jpg")
                                  
                                }
                                alt=""
                              />
                            </div>

                            <div className="efficiency-info__third margin">
                              <div className="efficiency-info-text">
                                <p>Perfectly yours.</p>
                                <p>You pick the colour, we match it.</p>
                                <div className="efficiency-info-text-mb">
                                  <p>
                                    Express your individuality with a virtually
                                    infinite combination of door styles,
                                    colours, glazing and accessories. If one of
                                    our 27 hues isn’t exactly what you’re
                                    looking for, our manufacturing process
                                    enables you to match your door to most
                                    colours. Simply bring along a RAL or BS
                                    colour reference and we’ll match it.
                                  </p>
                                </div>
                                <p>Low maintenance</p>
                                <p>
                                  To keep your Apeer door looking great, gently
                                  wipe down your door with warm soapy water and
                                  a soft cloth.
                                </p>
                              </div>
                            </div>
                            <div className="efficiency-info__first-item-img order-none">
                              <img
                                src={
                                  require("../../assets/images/energy-efficiency/Colour-Choices-3.jpg")
                                  
                                }
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <FavouriteStyleFooter>
                    <div className="favourite-style-footer-backbtn">
                      <a href="/"> {"< BACK"} </a>
                    </div>
                  </FavouriteStyleFooter>
                </div>
              </SimpleBar>
            </div>
          </div>
        </div>
      </Main>
    );
  }
}

export default ColourChoices;
